<template>
  <div class="lightSwitch">
    <div class="tips" @click="isShowTipsDialog = !isShowTipsDialog">
      <img src="./img/tips.png" alt="" />
    </div>
    <div class="light-icon">
      <img v-if="detailData.status == 1" src="./img/light-open.png" alt="" />
      <img v-else src="./img/light-close.png" alt="" />
    </div>
    <div class="btn" @click="handelChangeSwitch">
      <img v-if="detailData.status == 1" src="./img/btn-open.png" alt="" />
      <img v-else src="./img/btn.png" alt="" />
    </div>
    <v-dialog
      class="my-dialog"
      v-model="isShowTipsDialog"
      :showConfirmButton="false"
      :showCancelButton="false"
      :closeOnClickOverlay="true"
    >
      <div class="header">
        <img src="./img/light-tips.png" alt="" />
      </div>
      <div class="content-tips">
        <div class="title">照明使用说明</div>
        <div class="explain">
          <div class="text">
            按 <img src="./img/icon-9.png" alt="" /> 打开照明设备
          </div>
          <div class="text">
            按 <img src="./img/icon-10.png" alt="" /> 关闭照明设备
          </div>
        </div>

        <div class="footer">
          <div class="footer-btn" @click="isShowTipsDialog = false">
            我知道了
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { getEquipmentDetail, updateControlEquipment } from "./api.js";
export default {
  name: "lightSwitch",
  components: {},
  props: {},
  data() {
    return {
      newCode: "",
      detailData: {},
      id: "",
      isShowTipsDialog: false,
      timer: null,
    };
  },
  created() {},
  async mounted() {
    const { id, code } = this.$route.query;
    if (id != undefined) {
      this.id = id;
    }
    if (code != undefined) {
      this.newCode = code;
    }
    await this.getEquipmentDetail(id);
  },
  computed: {
    code() {
      return this.$store.state.code;
    },
    userId() {
      return this.$store.state.userId;
    },
  },
  methods: {
    handelChangeSwitch() {
      if (this.detailData.status == -1) {
        this.$toast({
          message: "未检测状态",
          duration: 300,
        });
        return;
      }
      const val = this.detailData.status == "1" ? 0 : 1;
      this.updateControlEquipment(val);
    },
    //控制
    updateControlEquipment(open) {
      const params = {
        ids: this.id,
        open,
      };
      let url = `https://cgzc-dev.zhuneng.cn/smartdeviceproxy/admin/huacong/self/control`;
      if (window.location.origin.indexOf("https://yshgjyd.nbhq.gov.cn") != -1) {
        url = `https://yshgjyd.nbhq.gov.cn/yz/jieneng/smartdeviceproxy/admin/huacong/self/control`;
      }
      this.$axios
        .get(url, {
          params,
          headers: {
            code: this.code || this.newCode,
          },
        })
        .then((res) => {
          if (res.code == 0) {
            // this.$toast({
            //   message: "操作成功",
            //   duration: 300,
            // });
            this.timer = setTimeout(() => {
              this.getEquipmentDetail(this.id, this.code);
            }, 4000);
          }
        });
    },
    //获取空调详情信息
    getEquipmentDetail(id, code) {
      let url = `https://cgzc-dev.zhuneng.cn/smartdeviceproxy/admin/huacong/self/${id}`;
      if (window.location.origin.indexOf("https://yshgjyd.nbhq.gov.cn") != -1) {
        url = `https://yshgjyd.nbhq.gov.cn/yz/jieneng/smartdeviceproxy/admin/huacong/self/${id}`;
      }
      this.$axios
        .get(url, {
          headers: {
            code: this.code || this.newCode,
          },
        })
        .then((res) => {
          if (res.code == 0) {
            const { data } = res;
            data.valueList.forEach((item) => {
              data[item.tagCode] = item.val;
            });
            this.detailData = data;
          }
        });
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
};
</script>

<style scoped lang="less">
.lightSwitch {
  width: 100%;
  min-height: 100vh;
  background: #f5eeec;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .tips {
    z-index: 12;
    position: fixed;
    top: 46px;
    left: 16px;
    width: 132px;
    height: 132px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .my-dialog {
    ::v-deep .van-dialog {
      top: 45%;
      width: 654px;
      background-color: transparent;
    }
    .van-dialog__content {
      .header {
        width: 100%;
        height: 380px;
        // background-image: url("./img/tips-header.png");
        // background-size: 100% 100%;
        // background-repeat: no-repeat;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .content-tips {
        padding-bottom: 60px;
        box-sizing: border-box;
        position: relative;
        top: -2px;
        width: 100%;
        box-sizing: border-box;
        background-color: #fff;
        .title {
          text-align: center;
          font-size: 48px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #32435a;
          line-height: 66px;
          margin-bottom: 12px;
        }
        .explain {
          box-sizing: border-box;
          margin-top: 90px;

          .text {
            text-align: center;
            font-size: 40px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #5a5e6d;
            line-height: 56px;
            &:first-child {
              margin-bottom: 60px;
            }
            img {
              width: 60px;
              height: 60px;
              vertical-align: middle;
            }
          }
        }

        .footer {
          margin-top: 72px;
          padding: 0 48px 0 48px;
          box-sizing: border-box;
          .footer-btn {
            padding: 20px 0;
            box-sizing: border-box;
            text-align: center;
            background: #fe4416;
            border-radius: 58px;
            width: 100%;
            font-size: 32px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 56px;
          }
        }
      }
    }
  }
  .light-icon {
    width: 90%;
    // width: 288px;
    // height: 542px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .btn {
    width: 454px;
    height: 454px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
